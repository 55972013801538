import appStrings from '@/app/utility/string.utility';
import Modules from '../../responsibility/searchModule';
import Menus from '../../role/searchMenu';
export default {
  name: 'MenuMappingDetails',
  components: {
    Modules,
    Menus
  },
  props: ['menuMappingDtl'],
  data() {
    return {
      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      loader: false,
      showModules: false,
      showMenus: false,
      moduleName: null,
      moduleId: null,
      parentMenuName: null,
      parentMenuId: null,
      childMenuName: null,
      childMenuId: null,
      menuMappingId: 0,
      sequenceOrder: null,
      editMode: false,
      menuType: null
    };
  },
  mounted() {
    this.fillRecordDetailsFormGrid();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if(mutation.type === 'shared/setActionName') {
            const actionName = state.shared.actionName;
            if(actionName === 'edit' || actionName === 'update') {
                this.editMode = true;
            }
            if(actionName === 'save') {
                this.saveEditMenuMapping();
            }
            if(actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.menuMappingId });
            }
        }
    })
  },
  methods: {
    fillRecordDetailsFormGrid() {
      if (this.menuMappingDtl) {
        this.moduleName = this.menuMappingDtl.module_name;
        this.moduleId = this.menuMappingDtl.module_id;
        this.parentMenuName = this.menuMappingDtl.parent_menu_name;
        this.parentMenuId = this.menuMappingDtl.parent_menu_id;
        this.childMenuName = this.menuMappingDtl.child_menu_name;
        this.childMenuId = this.menuMappingDtl.child_menu_id;
        this.menuMappingId = this.menuMappingDtl.menu_mpng_id;
        this.sequenceOrder = this.menuMappingDtl.sequence_order;
      }
      else {
          this.editMode = true;
      }
    },    
    selectedModule(item) {
      this.moduleName = item.module_name;
      this.moduleId = item.module_id;
      this.showHideModules(false);
    },
    showHideModules(flag) {
      this.showModules = flag;
    },
    showHideMenus(flag, menuType) {
      if(!this.moduleId){
        return alert(appStrings.DEPENDENTVSETMSG.MODULEMSG);
      }
      this.menuType = menuType;
      this.showMenus = flag;
    },
    selectedSearchMenu(item){
      if(this.menuType === "PARENT"){
        this.parentMenuId = item.menu_id;
        this.parentMenuName = item.menu_name;
      }
      else {
        if(this.parentMenuId === item.menu_id){
          return alert('Parent Menu and Child Menu cannot be same');
        }
        this.childMenuId = item.menu_id;
        this.childMenuName = item.menu_name;
      }
      this.showHideMenus(false, null);
    },
    saveEditMenuMapping() {
      if(!this.childMenuId){
        return alert('Please select Child Menu');
      }
      const payload = {
        child_menu_id: this.childMenuId,
        menu_mpng_id: this.menuMappingId,
        parent_menu_id: this.parentMenuId,
        sequence_order: this.sequenceOrder
      };
      this.$store
        .dispatch('menu/addEditMenuMappingRecord', payload)
        .then(response => {
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.$emit('updateMenuMappingList');
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
      this.unsubscribe();
  }
};
