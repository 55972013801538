import commonHelper from '@/app/utility/common.helper.utility';
import Modules from '../responsibility/searchModule';
import MenuMappingDetails from './menuMappingDetails';
export default {
  name: 'MenuMapping',
  components: {
    Modules,
    MenuMappingDetails
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getMenuMappingListData();
    },
    currentPage: function() {
      this.getMenuMappingListData();
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showModules: false,
      showMenuMappingDtls: false,
      loader: false,
      currentPage: 1,
      moduleName: null,
      moduleId: null,
      menuMappingDtl: null,
      menuMappingData: [],
      menuMappingFields: [
        {
          key: 'module_name',
          label: 'Module'
        },
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'menu_mpng_id',
          class: 'd-none'
        },
        {
          key: 'parent_menu_id',
          class: 'd-none'
        },
        {
          key: 'parent_menu_name',
          label: 'Parent Menu'
        },
        {
          key: 'child_menu_name',
          label: 'Child Menu'
        },
        {
          key: 'child_menu_id',
          class: 'd-none'
        },
        {
          key: 'sequence_order',
          label: 'Sequence'
        }
      ]
    };
  },
  mounted() {
    this.getMenuMappingListData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showMenuMappingDtls = true;
          this.menuMappingDtl = null;
        }
        if (
          actionName === 'download' &&
          !this.showMenuMappingDtls &&
          !this.showModules
        ) {
          this.loader = true;
          const downloadPayload = { ...this.payload };
          downloadPayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadPayload,
            'menu/getMenuMappingList',
            'menu-mapping',
            () => {
              this.loader = false;
            }
          );
        }
      }
    });
  },
  methods: {
    getMenuMappingListData() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.moduleId
      };
      this.loader = true;
      this.$store
        .dispatch('menu/getMenuMappingList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.menuMappingData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedModule(item) {
      this.moduleName = item.module_name;
      this.moduleId = item.module_id;
      this.showModules = false;
    },
    clearModule() {
      this.moduleId = null;
      this.moduleName = null;
      this.getMenuMappingListData();
    },
    showHideModal(flag) {
      this.showModules = flag;
    },
    rowSelected(item) {
      this.showMenuMappingDtls = true;
      this.menuMappingDtl = item;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
