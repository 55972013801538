import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'SearchMenu',
  watch: {
    currentPage: function() {
      this.getMenuList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMenuList();
    }
  },
  props: [ 'moduleId'],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      name: '',
      id:'',
      data: [],
      fields: [
        {
          key: 'Active',
          class: 'd-none'
        },
        {
          key: 'sub_module_id',
          class: 'd-none'
        },
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'menu_id',
          class: 'd-none'
        },
        {
          key: 'menu_name',
          label: 'Menu Name'
        },
        {
          key: 'menu_description',
          label: 'Menu Description'
        }
      ]
    };
  },
  mounted() {
    if(this.moduleId){
      this.getMenuList();
    }
    this.eventBus.$off('ModuleId');
    this.eventBus.$on('ModuleId', menuDependentId => {
      this.id = menuDependentId;
      this.getMenuList(this.id);
    })
  },
  methods: {
    rowSelected(item) {
      this.$emit('selectedSearchMenu', item);
    },
    clearMenu() {
      this.name = null;
      this.id = null;
    },
    getMenuList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.moduleId ? this.moduleId : this.id,
        menu_name: this.name
      };
      this.$store.dispatch('role/getMenuList', payload).then(response => {
        this.data = response.data.data.page;
        this.totalRows = response.data.data.total_elements;
      });
    }
  }
};
